<template>
  <template v-if="!bookingIsCancelledWithPayoutGuarantee">
    <ZTable :size="size">
      <!-- Rental amount -->
      <ZTableLineItem
        :discount="highlightLine === 'dates'"
        label-data-testid="rental-total-nights"
        :label="t('booking.rentalXnights', financialTotalNights)"
        :value="formatPrice({ value: financialTotalRentalAmount })"
        value-data-testid="rental-nights-cost"
      />

      <!-- Owner referral credits -->
      <ZTableLineItem
        v-if="financialOwnerReferralCredits"
        :label="t('booking.promo.affiliateBonus')"
        :value="formatPrice({ value: financialOwnerReferralCredits })"
      />

      <!-- Addons -->
      <BookingPriceBreakdownReceiptAddOns
        :financial="financial"
        :highlight="highlightLine === 'addons'"
      />

      <!-- Cleaning fee -->
      <ZTableLineItem
        v-if="financialTotalCleanings"
        :label="t('booking.cleaningFee.text')"
        :value="formatPrice({ value: financialTotalCleanings })"
        value-data-testid="cleaning-fee-addon"
      />

      <!-- Delivery -->
      <ZTableLineItem
        v-if="showTotalDelivery"
        :label="t('booking.delivery')"
        :value="formatPrice({ value: financialTotalDelivery })"
        value-data-testid="total-delivery-cost"
      />

      <!-- Owner sales taxes (business) -->
      <template v-if="financialTotalOwnerSalesTaxes">
        <ZTableLineItem
          :value="formatPrice({ value: financialTotalOwnerSalesTaxes })"
          is-clickable
          @click="showOwnerSalesTaxes = !showOwnerSalesTaxes"
        >
          <template #label>
            {{ t('booking.salesTaxesCollected') }}
            <ZToggleArrow
              :toggled="showOwnerSalesTaxes"
              class="text-highlight"
            />
          </template>
        </ZTableLineItem>

        <ZTableLineItem
          v-for="tax in financialOwnerSalesTaxAmounts"
          v-show="showOwnerSalesTaxes"
          :key="tax.Id"
          :value="formatPrice({ value: tax.Amount })"
          :label="tax.TaxCode ?? ''"
          :nested="1"
        />
      </template>
    </ZTable>

    <hr class="my-3">
  </template>

  <ZTable :size="size">
    <ZTableLineItem
      :label="t('booking.subtotal')"
      :value="formatPrice({ value: revenueTotal })"
      subtotal
    />

    <!-- Taxes and fees -->
    <ZTableLineItem
      is-clickable
      :value="showTaxesAndFees ? undefined : formatPrice({ value: taxesAndFeesTotal })"
      @click="toggleExpandFees"
    >
      <template #label>
        {{ t('booking.taxesAndFees') }}
        <ZToggleArrow
          :toggled="showTaxesAndFees"
          class="text-highlight"
        />
      </template>
    </ZTableLineItem>

    <!-- Fees -->
    <ZTableLineItem
      v-show="showTaxesAndFees"
      :label="t('booking.RVezyFee.text')"
      :value="formatPrice({ value: serviceFeeTotal })"
      value-data-testid="servicefeecharges"
      :nested="1"
    />

    <ZTableLineItem
      v-show="showTaxesAndFees && showTotalDelivery"
      :label="t('booking.deliveryFee.text')"
      :value="formatPrice({ value: financialTotalDeliveryFee })"
      value-data-testid="delivery-fee-charges"
      :nested="1"
    />

    <!-- Taxes -->
    <!-- Is this still needed? -->
    <!-- <ZTableLineItem
        v-if="isOwner && financialTotalOwnerTaxes"
        v-show="showTaxesAndFees"
        data-testid="provincialTax"
        :label="t('booking.tax')"
        label-data-testid="tax-TAX"
        :tooltip="t('booking.taxAdvise')"
        :value="formatPrice({ value: financialTotalOwnerTaxes })"
        value-data-testid="taxCharged-TAX"
        :nested="1"
      /> -->

    <ZTableLineItem
      v-for="tax in financialOwnerTaxAmounts"
      v-show="showTaxesAndFees"
      :key="tax.TaxCode ?? tax.Id"
      :label="t('booking.RVezyFee.withTax', { tax: tax.TaxCode })"
      :label-data-testid="`tax-${tax.TaxCode}`"
      :value="formatPrice({ value: tax.Amount })"
      :value-data-testid="`taxCharged-${tax.TaxCode}`"
      :nested="1"
      data-testid="provincialTax"
    />

    <slot name="prepend-total" />

    <ZTableLineItem
      v-if="!hideTotal && financialTotalCloseouts > 0"
      :label="t('additionalCharges')"
      :value="formatPrice({
        value: totalCloseoutChargesAmount,
      })"
      value-data-testid="renter-total-closeout-charges"
    />

    <slot name="append-total" />
  </ZTable>

  <template v-if="!hideTotal">
    <hr class="my-3">

    <ZTable
      :size="size"
      class="mb-0"
    >
      <ZTableLineItem
        :value="priceDisplay({
          value: amountToReceiveTotal,
          countryCode: financialCountry,
        })"
        value-data-testid="amount-to-receive-payment-details"
        total
      >
        <template #label>
          {{ t('booking.youReceive') }} ({{ financialCurrency }})
        </template>
      </ZTableLineItem>
    </ZTable>
  </template>

  <BookingPriceBreakdownReceiptOwnerFooter
    v-if="!hideReceiptFooter && amountToReceiveTotal > 0"
    :financial="financial"
  />
</template>

<script setup lang="ts">
import type { highlightedLine } from '~/components/booking/price-breakdown/receipt/types'
import type { FinancialCommon } from '~/types'
import type { TableSize } from '~/types/style-guide'

const props = defineProps<{
  financial?: FinancialCommon
  highlightLine?: highlightedLine
  hideTotal?: boolean
  deliveryDistance?: number
  size?: TableSize
  hideReceiptFooter?: boolean
}>()

const {
  financialCountry,
  financialCurrency,
  financialOwnerPayoutTotal,
  financialOwnerReferralCredits,
  financialOwnerSalesTaxAmounts,
  financialOwnerTaxAmounts,
  financialTotalAddOns,
  financialTotalCleanings,
  financialTotalCloseouts,
  financialTotalDelivery,
  financialTotalDeliveryFee,
  financialTotalIncomeProtection,
  financialTotalIncomeProtectionPayout,
  financialTotalNights,
  financialTotalOwnerSalesTaxes,
  financialTotalOwnerServiceFee,
  financialTotalOwnerServiceFeeWithoutDelivery,
  financialTotalOwnerTaxes,
  financialTotalRentalAmount,
} = useFinancialData(computed(() => props.financial))

const { bookingCompletedAdditionalBookingCharges, bookingIsCancelledWithPayoutGuarantee } = useBookingDetails()
const { userIsBookingRenter } = useBookingUsers()
const { rvCountry } = useRvDetails()

const { t } = useI18n()

const { priceDisplay } = usePrice()
function formatPrice({ value, showCurrencyCode }: { value: number, showCurrencyCode?: boolean }) {
  return priceDisplay({ value, countryCode: rvCountry.value, showCurrencyCode })
}

const showTotalDelivery = computed(() => {
  return financialTotalDelivery.value || props.deliveryDistance
})

const showOwnerSalesTaxes = ref(false)

const revenueTotal = computed(() => {
  return financialTotalRentalAmount.value + financialOwnerReferralCredits.value + financialTotalAddOns.value + financialTotalCleanings.value + financialTotalDelivery.value + financialTotalOwnerSalesTaxes.value
})

const showTaxesAndFees = ref(false)

function toggleExpandFees() {
  showTaxesAndFees.value = !showTaxesAndFees.value
}

const taxesAndFeesTotal = computed(() => {
  return financialTotalOwnerTaxes.value + financialTotalOwnerServiceFee.value
})

const serviceFeeTotal = computed(() => {
  return showTotalDelivery.value && financialTotalDeliveryFee.value
    ? financialTotalOwnerServiceFeeWithoutDelivery.value
    : financialTotalOwnerServiceFee.value
})

const totalCloseoutChargesAmount = computed(() => {
  return bookingCompletedAdditionalBookingCharges.value.reduce(
    (chargesTotal, charge) =>
      chargesTotal + charge.PayoutAmount + charge.OwnerServiceFee + charge.OwnerTaxAmount,
    0,
  )
})

const amountToReceiveTotal = computed(() => {
  if (userIsBookingRenter.value) {
    return 0
  }

  if (bookingIsCancelledWithPayoutGuarantee.value) {
    return financialTotalIncomeProtectionPayout.value || financialTotalIncomeProtection.value
  }

  return financialOwnerPayoutTotal.value
})
</script>

<i18n src="~/locales/common/booking/rvezy-fee.json" lang="json" />

<i18n src="~/locales/common/booking/cleaning-fee.json" lang="json" />

<i18n src="~/locales/common/booking/tax.json" lang="json" />

<i18n src="~/locales/common/booking.json" lang="json" />

<i18n lang="json">
  {
    "en": {
      "additionalCharges": "Additional Charges",
      "booking": {
        "promo": {
          "affiliateBonus": "Affiliate bonus"
        }
      }
    },
    "fr": {
      "additionalCharges": "Frais supplémentaires",
      "booking": {
        "promo": {
          "affiliateBonus": "Bonus d’affiliation"
        }
      }
    }
  }
  </i18n>
