<template>
  <div>
    <div
      v-if="bookingIsIncomeProtected"
      data-testid="income-protection-message"
      class="additional-message"
    >
      <h5>
        {{ t('incomeProtectionMessageTitle') }}
        <img
          class="ml-1"
          :src="`${runtimeConfig.public.staticAssetsURL}/web/images/payout-protect.svg`"
          alt=""
          loading="lazy"
          width="24"
          height="24"
        >
      </h5>

      <I18nT
        keypath="incomeProtectionMessageDescription"
        tag="span"
      >
        <template #link>
          <NuxtLink
            v-track-external-link="{ cta: 'tnc-income-protection' }"
            :to="t('termsLink')"
            target="_blank"
            class="terms-link"
          >
            {{ t('incomeProtectionMessageTnc') }}
          </NuxtLink>
        </template>
      </I18nT>
    </div>

    <div
      v-if="rvSnapshotHasSmartPricingApplied"
      class="additional-message"
      data-testid="smart-price-icon"
    >
      <h5>
        {{ t('smartPriceTitle') }}
        <Fa :icon="['fal', 'chart-mixed-up-circle-dollar']" />
      </h5>

      <span>{{ t('smartPriceDescription') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const { t } = useI18n()

const {
  bookingIsIncomeProtected,
  rvSnapshotHasSmartPricingApplied,
} = useBookingDetails()
</script>

<style lang="scss" scoped>
.additional-message {
  color: getColor('primary-500');
  border-radius: 0.5rem;
  padding: 0.75rem;
  background-color: getColor('primary-50');
  margin-top: 1.5rem;

  h5 {
    @include strong-1;

    svg {
      margin-left: 0.5rem;
      height: 24px;
      width: auto;
    }
  }

  span {
    @include caption;
  }

  .terms-link {
    @include caption;
    @include link;
    color: getColor('primary-500');
  }
}
</style>

<i18n src="~/locales/common/booking.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "incomeProtectionMessageTitle": "Payout guaranteed",
    "incomeProtectionMessageDescription": "You will get your full payout should your RV be damaged before this trip. {link}",
    "incomeProtectionMessageTnc": "Terms & conditions apply",
    "termsLink": "https://help.rvezy.com/hc/en-us/articles/12283837809051-How-can-I-guarantee-my-rental-payout-",

    "smartPriceTitle": "Smart pricing applied",
    "smartPriceDescription": "Smart pricing increases your minimum nightly rate by 10% when demand is high, but never drops below your set minimum."
  },
  "fr": {
    "incomeProtectionMessageTitle": "Ce revenu est garanti",
    "incomeProtectionMessageDescription": "Vous obtiendrez votre versement complet si votre VR est endommagé avant ce voyage. {link}",
    "incomeProtectionMessageTnc": "Les modalités s’appliquent",
    "termsLink": "https://help.rvezy.com/hc/fr-ca/articles/12283837809051-Comment-puis-je-garantir-mon-revenu-de-location-",

    "smartPriceTitle": "Tarification intelligente appliquée",
    "smartPriceDescription": "La tarification intelligente augmente de 10% votre tarif de nuit minimum lorsque la demande est forte, mais jamais en dessous du minimum fixé."
  }
}
</i18n>
